import rest from '@exchange/libs/utils/rest/src';

const URL = '/agreement';

export default function Resource({ base }) {
  const futuresAgreementUrl = `${URL}/futures-appropriateness-agreement`;
  const termsUrl = (date: string) => `${URL}/terms-${date}`;

  return {
    getFuturesAgreement: async (): Promise<{ date: string } | undefined> => {
      try {
        return await rest.request(
          {
            url: futuresAgreementUrl,
            base,
          },
          {
            useSubaccount: false,
          },
        );
      } catch (e) {
        if ((e as { status: number }).status === 404) {
          return undefined;
        }

        throw e;
      }
    },
    setFuturesAgreement: async (): Promise<void> =>
      rest.request(
        {
          url: futuresAgreementUrl,
          base,
          method: 'post',
        },
        {
          useSubaccount: false,
        },
      ),
    getTermsAgreement: async (date: string): Promise<{ date: string } | undefined> => {
      try {
        return await rest.request(
          {
            url: termsUrl(date),
            base,
          },
          {
            useSubaccount: false,
          },
        );
      } catch (e) {
        if ((e as { status: number }).status === 404) {
          return undefined;
        }
        throw e;
      }
    },
    setTermsAgreement: async (date: string): Promise<void> =>
      rest.request(
        {
          url: termsUrl(date),
          base,
          method: 'post',
        },
        {
          useSubaccount: false,
        },
      ),
  };
}
