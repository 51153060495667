import WebRest from '@exchange/libs/rest-api/web-api';
import type { FuturesAppropriatenessResult } from '@exchange/libs/rest-api/web-api/customer/futures-appropriateness-resource';
import { retryService } from '@exchange/libs/utils/retry/src';
import { logger } from '@exchange/libs/utils/simple-logger/src';

class FuturesService {
  public getFuturesAppropriatenessAssessmentResult = async (): Promise<FuturesAppropriatenessResult | undefined> => {
    try {
      const data = await WebRest.Customer.FuturesAppropriateness.get();

      return data?.result;
    } catch (error) {
      logger.error('Fetching futures appropriateness assessment failed; retrying later', error);
      await retryService.waitForNextRetryTick();
      return this.getFuturesAppropriatenessAssessmentResult();
    }
  };

  public futuresAgreement = {
    get: async (): Promise<{ date: string } | undefined> => {
      try {
        const data = await WebRest.Customer.Agreement.getFuturesAgreement();

        return data;
      } catch (error) {
        logger.error('Fetching futures disclaimer failed; retrying later', error);
        await retryService.waitForNextRetryTick();
        return this.futuresAgreement.get();
      }
    },
    set: () => WebRest.Customer.Agreement.setFuturesAgreement(),
  };

  public getLeverage = async (): Promise<number | null> => {
    try {
      return await WebRest.Account.Futures.getLeverage();
    } catch (error) {
      logger.error('Fetching futures leverage failed; retrying later', error);
      await retryService.waitForNextRetryTick();
      return this.getLeverage();
    }
  };
}

const futuresService = new FuturesService();

export default futuresService;
