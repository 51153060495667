import AccountResources from './account';
import BlockchainResource from './blockchain';
import CandlesticksResource from './candlesticks-resource';
import CustomerResources from './customer';
import ExportsResources from './exports';
import FundingResource from './funding';
import UserResources from './user';

const API_DOMAIN = process.env.VUE_APP_API_DOMAIN;
const API_FAST_DOMAIN = process.env.VUE_APP_API_DOMAIN_FAST;
const API_SUFFIX = 'web';
const API_VERSION = 'v1';
const WEB_API_BASE = `${API_DOMAIN}/${API_SUFFIX}/${API_VERSION}`;

const API_FAST_BASE = `${API_FAST_DOMAIN}/${API_VERSION}`;

const WebRest = {
  Account: { ...AccountResources({ base: API_FAST_BASE }) },
  Blockchain: BlockchainResource({ base: API_DOMAIN }),
  Candlesticks: CandlesticksResource({ base: API_FAST_BASE }),
  Customer: CustomerResources({ base: API_FAST_BASE }),
  Exports: { ...ExportsResources({ base: API_FAST_BASE }) },
  Funding: FundingResource({ base: WEB_API_BASE }),
  User: (id: string) => UserResources({ base: WEB_API_BASE, baseFast: API_FAST_BASE, userId: id }),
};

export default WebRest;
